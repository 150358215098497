import { HttpAgent, Actor } from '@dfinity/agent';
import { idlFactory } from '../smart-contracts/service.js';

let actor = null

async function HttpAgentInit() {
    if(actor == null) {
        const agent = new HttpAgent({ host: process.env.REACT_APP_PUBLIC_HOST });
        actor = Actor.createActor(idlFactory, { agent,  canisterId: process.env.REACT_APP_CANISTER_ID });
        if(process.env.REACT_APP_DFX_NETWORK != "ic") {
            await agent.fetchRootKey();
        }    
    }
    
    return actor;
}

export default HttpAgentInit;