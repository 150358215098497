import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "../store";
import axios from "../utils/Axios"
import alert from "../utils/Alert.js";
import { toast } from "react-toastify";
import HttpAgentInit from "./HttpAgentInit.js";

export const APIContext = React.createContext();
const userAgent = navigator.userAgent;

const showMessage = (data) => {
    if(data.status == "error") {
        if(data.msg) {
            console.log(data.msg)
            toast.warning(data.msg);
        }
    
        return data;
    } else {
        if(data.msg)
            toast.success(data.msg);
    
        return data;
    }
}

export const APIProvider = ({ children }) => {
    const verification = async ( mobile ) => {
        const actor = await HttpAgentInit();

        let res = await actor.admin_verification(mobile);

        let data = JSON.parse(res);

        showMessage(data);

        return data;
    };

    const login = async ( userInfo ) => {
        const actor = await HttpAgentInit();

        let res = await actor.admin_login(JSON.stringify(userInfo), userAgent);

        let data = JSON.parse(res);

        showMessage(data);

        return data;
    };

    const dashboard = async (userInfo) => {
        const actor = await HttpAgentInit();

        let res = await actor.admin_dashboard(JSON.stringify(userInfo), userAgent);

        let data = JSON.parse(res);

        showMessage(data);

        return data;
    }

    const getActivity = async (userInfo, currentPage) => {
        const actor = await HttpAgentInit();

        let res = await actor.get_activity(JSON.stringify(userInfo), String(currentPage), userAgent);

        let data = JSON.parse(res);

        showMessage(data);

        return data;
    }

    const getActivityByUserId = async (userId) => {
        const actor = await HttpAgentInit();

        let res = await actor.get_all_activity(String(userId));

        let data = JSON.parse(res);

        showMessage(data);

        return data;
    }

    const getUserInfosAPI = async () => {
        const actor = await HttpAgentInit();

        let res = await actor.get_user_Infos();

        let data = JSON.parse(res);

        showMessage(data);

        return data;
    }

    const getReferralByUserId = async (userId) => {
        const actor = await HttpAgentInit();

        let res = await actor.get_all_referral(String(userId));

        let data = JSON.parse(res);

        showMessage(data);

        return data;
    }

    const deleteActivity = async (userInfo, id) => {
        const actor = await HttpAgentInit();

        let res = await actor.delete_activity(JSON.stringify(userInfo), String(userId));

        let data = JSON.parse(res);

        showMessage(data);

        return data;
    }

    const verify2FACode = async (userInfo, code, secretKey, google2fa) => {
        const actor = await HttpAgentInit();

        let res = await actor.verify_2fa(JSON.stringify(userInfo), String(code), secretKey, google2fa);

        let data = JSON.parse(res);

        showMessage(data);

        return data;
    }

    const updateProfileInfo = async (userInfo, name, login, birthday, nationality) => {
        const actor = await HttpAgentInit();

        let res = await actor.update_profile(JSON.stringify(userInfo), name, login, birthday, nationality, userAgent);

        let data = JSON.parse(res);

        showMessage(data);

        return data;
    }

    const getPastMessages = async (selectedId) => {
        const actor = await HttpAgentInit();

        let res = await actor.get_past_messages(String(selectedId));

        let data = JSON.parse(res);

        showMessage(data);

        return data;
    }

    const uploadImageFile = async (filename, file) => {
        const data = await axios.post("/assets/write-file", {
           filename, file
        });

        return data;
    }

    const uploadDBAPI = async (dbInfo, index, total) => {
        const actor = await HttpAgentInit();

        let res = await actor.init_db(dbInfo, String(index), String(total));

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }

    const uploadSecondDBAPI = async (dbInfo, index, total) => {
        let baseUrl = "https://" + process.env.REACT_APP_SEC_CANISTER_ID + ".raw.icp0.io";

        console.log("baseUrl", baseUrl)

        let data;

        try {
            data = await axios.post(baseUrl + "/auth/init_db", {
                dbInfo, index, total
            });
        } catch(error) {
            console.log(error)
        }

        console.log("data", data)

        return data;
    }

    const getTrnxAPI = async (searchInfo) => {
        const actor = await HttpAgentInit();

        let res = await actor.get_trnxs(JSON.stringify(searchInfo), userAgent);

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }

    const getKYCList = async (searchInfo) => {
        const actor = await HttpAgentInit();

        let res = await actor.get_kyc_list(JSON.stringify(searchInfo), userAgent);

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }

    const getMessages = async (searchInfo) => {
        const actor = await HttpAgentInit();

        let res = await actor.get_admin_messages(JSON.stringify(searchInfo), userAgent);

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }

    const getDetailTrnx = async (id) => {
        const actor = await HttpAgentInit();

        let res = await actor.get_detail_trnx(String(id));

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }

    const getDetailUserAPI = async (id) => {
        const actor = await HttpAgentInit();

        let res = await actor.get_detail_user(String(id));

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }

    const getDetailKycInfo = async (id) => {
        const actor = await HttpAgentInit();

        let res = await actor.get_detail_kyc(String(id));

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }

    const requestWalletChange = async (userInfo) => {
        const actor = await HttpAgentInit();

        let res = await actor.request_wallet_change(JSON.stringify(userInfo), userAgent);

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }

    const walletActionAPI = async (type, id) => {
        const actor = await HttpAgentInit();

        let res = await actor.wallet_action(type, String(id));

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }

    const getAmbassadors = async (userInfo) => {
        const actor = await HttpAgentInit();

        let res = await actor.get_ambassadores(JSON.stringify(userInfo), userAgent);

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }

    const getUserListAPI = async (searchInfo) => {
        const actor = await HttpAgentInit();

        let res = await actor.get_user_list(JSON.stringify(searchInfo));

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }

    const getWalletListAPI = async (searchInfo) => {
        const actor = await HttpAgentInit();

        let res = await actor.get_wallet_list(searchInfo);

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }

    const getStageInfoAPI = async (userInfo) => {
        const actor = await HttpAgentInit();

        let res = await actor.get_stage_info(JSON.stringify(userInfo), userAgent);

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }

    const getICOSettingInfoAPI = async (userInfo) => {
        const actor = await HttpAgentInit();

        let res = await actor.get_ico_setting(JSON.stringify(userInfo), userAgent);

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }

    const getWebSettingInfoAPI = async (userInfo) => {
        const actor = await HttpAgentInit();

        let res = await actor.get_webSite_setting(JSON.stringify(userInfo), userAgent);

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }

    const getReferralSettingInfoAPI = async () => {
        const actor = await HttpAgentInit();

        let res = await actor.get_referral_setting();

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }

    const getSalesGraphAPI = async (userInfo, tnxParam) => {
        const actor = await HttpAgentInit();

        let res = await actor.get_sales_graph(JSON.stringify(userInfo), JSON.stringify(tnxParam));

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }

    const getRegistrationGraphAPI = async (userInfo, tnxParam) => {
        const actor = await HttpAgentInit();

        let res = await actor.get_registration_graph(JSON.stringify(userInfo), JSON.stringify(tnxParam));

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }

    const addFundsAPI = async (fundsInfo, userInfo) => {
        const actor = await HttpAgentInit();

        let res = await actor.add_funds(JSON.stringify(fundsInfo), JSON.stringify(userInfo), userAgent);

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }

    const getDetailInfoForFundsAPI = async () => {
        const actor = await HttpAgentInit();

        let res = await actor.get_detail_funds();

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }

    const getAllTrnxByUserId = async (userId) => {
        const actor = await HttpAgentInit();

        let res = await actor.get_trnx_by_user(String(userId));

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }
    
    const updateTrnxAPI = async (trnxInfo) => {
        const actor = await HttpAgentInit();

        let res = await actor.update_trnx(JSON.stringify(trnxInfo));

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }

    const updateTrnxNoteAPI = async (id, note) => {
        const actor = await HttpAgentInit();

        let res = await actor.update_trnx_note(String(id), note);

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }

    const updateUserNoteAPI = async (id, note) => {
        const actor = await HttpAgentInit();

        let res = await actor.update_user_note(String(id), note);

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }

    const updateUserAPI = async (userInfo) => {
        const actor = await HttpAgentInit();

        let res = await actor.update_user(JSON.stringify(userInfo));

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }

    const addBonusAPI = async (userId, bonusPhones, admin) => {
        const actor = await HttpAgentInit();

        let res = await actor.add_bonus(String(userId), JSON.stringify(bonusPhones), admin);

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }

    const getBonusTrnxAPI = async (userId, bonusPhones) => {
        const actor = await HttpAgentInit();

        let res = await actor.get_bonus_transactions(String(userId), JSON.stringify(bonusPhones));

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }

    const sendMessageToClient = async (adminInfo, message, userId) => {
        const actor = await HttpAgentInit();

        let res = await actor.send_msg_client(message, JSON.stringify(adminInfo), String(userId), userAgent);

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }

    const refundTrnxAPI = async (trnxId, message, adminInfo) => {
        const actor = await HttpAgentInit();

        let res = await actor.refund_trnx(String(trnxId), message, JSON.stringify(adminInfo));

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }

    const addNewUserAPI = async (userInfo) => {
        const actor = await HttpAgentInit();

        let res = await actor.add_new_user(JSON.stringify(userInfo));

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }

    const updateKycStatus = async (kycId, note, status, adminInfo) => {
        console.log("kycId", kycId)

        const actor = await HttpAgentInit();

        let res = await actor.update_kyc_status(String(kycId), note, status, JSON.stringify(adminInfo));

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    }

    const signInAddress = async (publicAddress, signature) => {
        const actor = await HttpAgentInit();

        let res = await actor.sign_admin_metamask(publicAddress, signature, userAgent);

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    };

    const checkAddress = async (publicAddress) => {
        const actor = await HttpAgentInit();

        let res = await actor.check_wallet_address(publicAddress);

        let data = JSON.parse(res);

        showMessage(data);
        
        return data;
    };

    return (
        <APIContext.Provider
            value={{
                signInAddress,
                checkAddress,
                verification,
                login,
                dashboard,
                addFundsAPI,
                updateKycStatus,
                addNewUserAPI,
                updateTrnxAPI,
                refundTrnxAPI,
                getBonusTrnxAPI,
                getDetailInfoForFundsAPI,
                getAllTrnxByUserId,
                getTrnxAPI,
                walletActionAPI,
                deleteActivity,
                getICOSettingInfoAPI,
                getReferralByUserId,
                getReferralSettingInfoAPI,
                requestWalletChange,
                getMessages,
                updateTrnxNoteAPI,
                updateUserNoteAPI,
                getRegistrationGraphAPI,
                sendMessageToClient,
                getSalesGraphAPI,
                uploadDBAPI,
                uploadSecondDBAPI,
                getKYCList,
                getStageInfoAPI,
                getWebSettingInfoAPI,
                addBonusAPI,
                getDetailKycInfo,
                getUserListAPI,
                getDetailTrnx,
                getAmbassadors,
                getDetailUserAPI,
                getUserInfosAPI,
                updateUserAPI,
                getActivity,
                getActivityByUserId,
                getWalletListAPI,
                getPastMessages,
                updateProfileInfo,
                uploadImageFile,
                verify2FACode
            }}
        >
            {children}
        </APIContext.Provider>
    );
};


