import React, { lazy, useEffect, useMemo } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { I18nextProvider } from 'react-i18next';

import { Logout, UpdateBalance } from "./store/reducers/auth";
import { APIProvider } from "./context/ApiContext";
import { Toaster } from "react-hot-toast";
import Alert from "./components/Alert/Alert";
import { AgentProvider } from "@ic-reactor/react"
import { useSelector } from "./store";
import alert from "./utils/Alert";
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react'
import { chains } from "./utils/Chains";
import { WagmiConfig } from "wagmi";

const ModalLayout = lazy(() => import("./pages/admin/modal/ModalLayout"))
const AdminLayout = lazy(() => import("./pages/admin/layout/Layout"));
const AuthLayout = lazy(() => import("./pages/admin/layout/AuthLayout"));
const HelpLayout = lazy(() => import("./pages/admin/layout/HelpLayout"));

const projectId = '9343d9c2b8f3ecd2bc5e2649f9b8390e'

const metadata = {
  name: 'Robotbulls',
  description: 'Robotbulls wallet',
  url: 'https://app.robotbulls.com',
  icons: null
}

const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata })
createWeb3Modal({ wagmiConfig, projectId, chains })

function App() {
  const history = useHistory();

  const { isLoggedIn, balance, token, user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  return (
    <>      
        <WagmiConfig config={wagmiConfig}>

          <ModalLayout/>
          <Switch>
            {/* Place new routes over this */}
            <Route index path="/admin" component={AdminLayout} />

            <Route index path="/auth" component={AuthLayout} />

            <Route index path="/help" component={HelpLayout} />
            {/* If you have an index page, you can remothis Redirect */}
            <Redirect exact from="/" to="/auth/register" />      
                    
            <Route path="*" render={() => <Redirect to="/" />} />
          </Switch>
          <Alert/>
          <Toaster
            position="top-right"
            reverseOrder={false}
          />       
        </WagmiConfig>
    </>
  );
}

export default App;
