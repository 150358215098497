export const idlFactory = ({ IDL }) => {
  return IDL.Service({
    'add_bonus' : IDL.Func([IDL.Text, IDL.Text, IDL.Text], [IDL.Text], []),
    'add_funds' : IDL.Func([IDL.Text, IDL.Text, IDL.Text], [IDL.Text], []),
    'add_new_user' : IDL.Func([IDL.Text], [IDL.Text], []),
    'admin_dashboard' : IDL.Func([IDL.Text, IDL.Text], [IDL.Text], []),
    'admin_login' : IDL.Func([IDL.Text, IDL.Text], [IDL.Text], []),
    'admin_verification' : IDL.Func([IDL.Text], [IDL.Text], []),
    'check_wallet_address' : IDL.Func([IDL.Text], [IDL.Text], []),
    'dashboard' : IDL.Func([IDL.Text, IDL.Text], [IDL.Text], []),
    'delete_activity' : IDL.Func([IDL.Text, IDL.Text], [IDL.Text], []),
    'get_account_balance' : IDL.Func([IDL.Text, IDL.Text], [IDL.Text], []),
    'get_activity' : IDL.Func([IDL.Text, IDL.Text, IDL.Text], [IDL.Text], []),
    'get_admin_messages' : IDL.Func([IDL.Text, IDL.Text], [IDL.Text], []),
    'get_all_activity' : IDL.Func([IDL.Text], [IDL.Text], []),
    'get_all_referral' : IDL.Func([IDL.Text], [IDL.Text], []),
    'get_ambassadores' : IDL.Func([IDL.Text, IDL.Text], [IDL.Text], []),
    'get_application' : IDL.Func([IDL.Text], [IDL.Text], []),
    'get_bonus_transactions' : IDL.Func([IDL.Text, IDL.Text], [IDL.Text], []),
    'get_coin_data' : IDL.Func([IDL.Text, IDL.Text], [IDL.Text], []),
    'get_dashboard_equity' : IDL.Func([IDL.Text, IDL.Text], [IDL.Text], []),
    'get_detail_funds' : IDL.Func([], [IDL.Text], []),
    'get_detail_kyc' : IDL.Func([IDL.Text], [IDL.Text], []),
    'get_detail_trnx' : IDL.Func([IDL.Text], [IDL.Text], []),
    'get_detail_user' : IDL.Func([IDL.Text], [IDL.Text], []),
    'get_expected_rol' : IDL.Func([IDL.Text], [IDL.Text], []),
    'get_ico_setting' : IDL.Func([IDL.Text, IDL.Text], [IDL.Text], []),
    'get_investment' : IDL.Func([IDL.Text, IDL.Text, IDL.Text], [IDL.Text], []),
    'get_kyc_list' : IDL.Func([IDL.Text, IDL.Text], [IDL.Text], []),
    'get_messages' : IDL.Func([IDL.Text], [IDL.Text], []),
    'get_past_messages' : IDL.Func([IDL.Text, IDL.Text], [IDL.Text], []),
    'get_referral_setting' : IDL.Func([], [IDL.Text], []),
    'get_registration_graph' : IDL.Func([IDL.Text, IDL.Text], [IDL.Text], []),
    'get_sales_graph' : IDL.Func([IDL.Text, IDL.Text], [IDL.Text], []),
    'get_stage_info' : IDL.Func([IDL.Text, IDL.Text], [IDL.Text], []),
    'get_transaction' : IDL.Func(
        [IDL.Text, IDL.Text, IDL.Text, IDL.Text, IDL.Text],
        [IDL.Text],
        [],
      ),
    'get_trnx_by_user' : IDL.Func([IDL.Text], [IDL.Text], []),
    'get_trnxs' : IDL.Func([IDL.Text, IDL.Text], [IDL.Text], []),
    'get_userInfo' : IDL.Func([IDL.Text], [IDL.Text], []),
    'get_user_Infos' : IDL.Func([], [IDL.Text], []),
    'get_user_list' : IDL.Func([IDL.Text], [IDL.Text], []),
    'get_wallet_info' : IDL.Func([IDL.Text], [IDL.Text], []),
    'get_wallet_list' : IDL.Func([IDL.Text], [IDL.Text], []),
    'get_webSite_setting' : IDL.Func([IDL.Text, IDL.Text], [IDL.Text], []),
    'http_request' : IDL.Func(
        [
          IDL.Record({
            'url' : IDL.Text,
            'method' : IDL.Text,
            'body' : IDL.Vec(IDL.Nat8),
            'headers' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
            'certificate_version' : IDL.Opt(IDL.Nat16),
          }),
        ],
        [
          IDL.Record({
            'body' : IDL.Vec(IDL.Nat8),
            'headers' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
            'upgrade' : IDL.Opt(IDL.Bool),
            'streaming_strategy' : IDL.Opt(
              IDL.Variant({
                'Callback' : IDL.Record({
                  'token' : IDL.Vec(IDL.Nat8),
                  'callback' : IDL.Func(
                      [IDL.Vec(IDL.Nat8)],
                      [
                        IDL.Opt(
                          IDL.Record({
                            'token' : IDL.Opt(IDL.Vec(IDL.Nat8)),
                            'body' : IDL.Vec(IDL.Nat8),
                          })
                        ),
                      ],
                      ['query'],
                    ),
                }),
              })
            ),
            'status_code' : IDL.Nat16,
          }),
        ],
        ['query'],
      ),
    'http_request_update' : IDL.Func(
        [
          IDL.Record({
            'url' : IDL.Text,
            'method' : IDL.Text,
            'body' : IDL.Vec(IDL.Nat8),
            'headers' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
          }),
        ],
        [
          IDL.Record({
            'body' : IDL.Vec(IDL.Nat8),
            'headers' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
            'upgrade' : IDL.Opt(IDL.Bool),
            'streaming_strategy' : IDL.Opt(
              IDL.Variant({
                'Callback' : IDL.Record({
                  'token' : IDL.Vec(IDL.Nat8),
                  'callback' : IDL.Func(
                      [IDL.Vec(IDL.Nat8)],
                      [
                        IDL.Opt(
                          IDL.Record({
                            'token' : IDL.Opt(IDL.Vec(IDL.Nat8)),
                            'body' : IDL.Vec(IDL.Nat8),
                          })
                        ),
                      ],
                      ['query'],
                    ),
                }),
              })
            ),
            'status_code' : IDL.Nat16,
          }),
        ],
        [],
      ),
    'init_db' : IDL.Func([IDL.Text, IDL.Text, IDL.Text], [IDL.Text], []),
    'login' : IDL.Func([IDL.Text, IDL.Text], [IDL.Text], []),
    'refund_trnx' : IDL.Func([IDL.Text, IDL.Text, IDL.Text], [IDL.Text], []),
    'register_activity' : IDL.Func(
        [IDL.Text, IDL.Text, IDL.Text],
        [IDL.Text],
        [],
      ),
    'request_wallet_change' : IDL.Func([IDL.Text, IDL.Text], [IDL.Text], []),
    'send_message' : IDL.Func([IDL.Text, IDL.Text, IDL.Text], [IDL.Text], []),
    'send_msg_client' : IDL.Func(
        [IDL.Text, IDL.Text, IDL.Text, IDL.Text],
        [IDL.Text],
        [],
      ),
    'sign_admin_metamask' : IDL.Func(
        [IDL.Text, IDL.Text, IDL.Text],
        [IDL.Text],
        [],
      ),
    'sign_metamask' : IDL.Func([IDL.Text, IDL.Text, IDL.Text], [IDL.Text], []),
    'transform' : IDL.Func(
        [
          IDL.Record({
            'context' : IDL.Vec(IDL.Nat8),
            'response' : IDL.Record({
              'status' : IDL.Nat,
              'body' : IDL.Vec(IDL.Nat8),
              'headers' : IDL.Vec(
                IDL.Record({ 'value' : IDL.Text, 'name' : IDL.Text })
              ),
            }),
          }),
        ],
        [
          IDL.Record({
            'status' : IDL.Nat,
            'body' : IDL.Vec(IDL.Nat8),
            'headers' : IDL.Vec(
              IDL.Record({ 'value' : IDL.Text, 'name' : IDL.Text })
            ),
          }),
        ],
        ['query'],
      ),
    'update_kyc_status' : IDL.Func(
        [IDL.Text, IDL.Text, IDL.Text, IDL.Text],
        [IDL.Text],
        [],
      ),
    'update_profile' : IDL.Func(
        [IDL.Text, IDL.Text, IDL.Text, IDL.Text, IDL.Text, IDL.Text],
        [IDL.Text],
        [],
      ),
    'update_trnx' : IDL.Func([IDL.Text], [IDL.Text], []),
    'update_trnx_note' : IDL.Func([IDL.Text, IDL.Text], [IDL.Text], []),
    'update_user' : IDL.Func([IDL.Text], [IDL.Text], []),
    'update_user_note' : IDL.Func([IDL.Text, IDL.Text], [IDL.Text], []),
    'update_wallet' : IDL.Func(
        [IDL.Text, IDL.Text, IDL.Text, IDL.Text],
        [IDL.Text],
        [],
      ),
    'upload_application' : IDL.Func(
        [IDL.Text, IDL.Text, IDL.Text],
        [IDL.Text],
        [],
      ),
    'verification' : IDL.Func([IDL.Text], [IDL.Text], []),
    'verify_2fa' : IDL.Func(
        [IDL.Text, IDL.Text, IDL.Text, IDL.Text, IDL.Text],
        [IDL.Text],
        [],
      ),
    'wallet_action' : IDL.Func([IDL.Text, IDL.Text], [IDL.Text], []),
  });
};
export const init = ({ IDL }) => { return []; };
