import { createSlice } from '@reduxjs/toolkit';
import { act } from 'react';
import _ from "lodash";

const initialUser = {
    id: '',
    name: '',
    mobile: '',
    email: '',
    isActive: false,
    google2fa: '',
    base_currency: '',
    secretKey: '',
    google2faUrl: '',
    nationality: 'none',
    dateOfBirth: '',
    walletType: '',
    walletAddress: ''
};

const initialState = {
    isInitialized: true,
    isLoggedIn: false,
    user: initialUser,
    transactionDetail: null,
    trnx: [],
    selectedId: {},
    lang: 'EN',
    isRefresh: false
};

const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        Login(state, action) {
            const { userInfo } = action.payload;

            state.user = userInfo;
            state.isLoggedIn = true;
            state.isInitialized = true;
        },

        UpdateInfo(state, action) {
            const {userInfo} = action.payload;

            state.user = _.merge({}, state.user, userInfo);
        },

        UpdateBalance(state, action) {
            state.user.balance = action.payload;
        },

        UpdateTrnx(state, action) {
            state.trnx = action.payload;
        },

        UpdateList(state, action) {
            let ac = action.payload;
            let info = state.isRefresh;

            state.isRefresh = !info;
        },

        UpdateSelectedId(state, action) {
            state.selectedId = action.payload;
        },

        UpdateBalances(state, action) {
            const balance = action.payload;
            state.balance = balance.balance;
            state.balanceId = balance._id;
            state.currency = balance.currency;
            state.currencyId = balance.currency._id;
            state = { ...state };
        },

        SetNowpayMinAmount(state, action) {
            state.nowpayMinAmount = action.payload.minAmount;
        },

        SetBalances(state, action) {
            state.balances = action.payload
        },

        SetCode(state, action) {
            state.code = action.payload;
        },

        SetTransactionDetail(state, action) {
            state.transactionDetail = action.payload;
        },

        SetBetsId(state, action) {
            state.betsId = action.payload;
        },

        Logout(state, action) {
            state.token = '';
            state.balance = 0;
            state.user = initialUser;
            state.isLoggedIn = false;
            state.dgToken = "";
            state.isInitialized = true;
            state = { ...state };
        },

        UpdateToken(state, action) {
            state.token = action.payload;
        },

        SetCurrentProvider(state, action) {
            state.provider = action.payload;
        },

        ChangeLanguage(state, action) {
            state.lang = action.payload;
        }
    }
});

export default auth.reducer;

export const { Login, Logout, UpdateTrnx, UpdateList, UpdateInfo, UpdateBalances, SetTransactionDetail, UpdateSelectedId, ChangeLanguage, SetBalances, UpdateBalance, SetCode, SetBetsId, UpdateToken, SetNowpayMinAmount, SetCurrentProvider } = auth.actions;
