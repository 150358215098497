import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// import en from './en.json';
import fr from './fr.json';
import en from "./en.json";
import bg from "./bg.json";
import cn from "./cn.json";
import de from "./de.json";
import es from "./es.json";
import it from "./it.json";
import lt from "./lt.json";
import no from "./no.json";
import ru from "./ru.json";
import sv from "./sv.json";
import ua from "./ua.json";
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    debug: true,
    resources: {
      en: {
        translation: en
      },
      fr: {
        translation: fr
      },
      bg: {
        translation: bg
      },
      cn: {
        translation: cn
      },
      de: {
        translation: de
      },
      es: {
        translation: es
      },
      it: {
        translation: it
      },
      lt: {
        translation: lt
      },
      no: {
        translation: no
      },
      ru: {
        translation: ru
      },
      sv: {
        translation: sv
      },
      ua: {
        translation: ua
      }
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;